import { Route } from '@angular/router';
import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['/dashboard']);

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@goalmate/goalmate/home').then((m) => m.GoalmateHomeModule),
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard },
    loadChildren: () => import('@goalmate/auth').then((m) => m.AuthModule),
  },
  {
    path: 'verify',
    loadComponent: () =>
      import('./components/verify/verify.component').then(
        (m) => m.VerifyComponent,
      ),
  },
  { path: '**', redirectTo: '/dashboard' },
];
