import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { register } from 'swiper/element';

@Component({
  selector: 'goalmate-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'goalmate';
  private document = inject(DOCUMENT);

  ngOnInit() {
    this.initTheme();
    register();
  }

  private initTheme() {
    const defaultTheme = 'goalmate-light';
    const theme = localStorage.getItem('theme');
    if (theme) {
      this.document.documentElement.setAttribute('data-theme', theme);
    } else {
      this.document.documentElement.setAttribute('data-theme', defaultTheme);
    }
  }
}
